<template>
  <div class='element materialShadow size_50 notifications'>
    <div v-if="hasLoaded && notifications.length === 0" class='emptyList'>
      <icon name='fa-exclamation-circle'/><br>{{ x('noNewLikesOrComments') }}
    </div>
    <notification v-for="notification in notifications" :notification="notification"
                  :key="notification.id"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import { httpGet } from '../../classes/httpHelper';
  import translate from '../Mixins/Translate';
  import Notification from '../Notifications/Notification';
  import Events from '../../enums/event-names';

  export default {
    name: 'admin-feed-notifications-list',
    components: {
      notification: Notification,
    },
    mixins: [translate],
    data() {
      return {
        hasLoaded: false,
      };
    },
    computed: {
      ...mapState(['notifications']),
    },
    async mounted() {
      this.registerEventHandlers();
      await this.loadNotifications();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.notification, this.onReceivedNotification);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.notification, this.onReceivedNotification);
      },
      onReceivedNotification(event) {
        console.log('Received notification: ', event);
        const notification = this.parseNotificationParameters(event);
        this.$store.commit('pushNotification', notification);
      },
      async loadNotifications() {
        const adminFeedNotificationTimerStamp = moment().subtract(1, 'day').toISOString();
        let notifications = await httpGet(`system/notifications?mintime=${adminFeedNotificationTimerStamp}`);
        notifications = notifications.map(this.parseNotificationParameters);
        this.$store.commit('setNotifications', notifications);
        this.hasLoaded = true;
      },
      parseNotificationParameters(notification) {
        if (!notification.parameter) return notification;
        const obj = notification;
        const temp = JSON.parse(obj.parameter);
        for (const prop in temp) {
          if (Object.prototype.hasOwnProperty.call(temp, prop)) {
            obj[prop] = temp[prop];
          }
        }
        return obj;
      },
    },
  };
</script>
