<template>
  <div>
    <div class='element materialShadow size_50 admin_feed_map'>
      <div class="map-container">
        <map-component :points="points" />
      </div>
      <br>
      <feed-stats-box :items="regionItems" :title="x('districtsDashMostVisits')"/>
      <br><br>
      <feed-stats-box :items="brandItems" :title="x('productsDashMostVisits')"/>
    </div>
    <admin-feed :items="items"/>
    <admin-feed-notifications-list v-if="showNotificationsColumn"/>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { debounce } from 'lodash';

  import { httpGet } from '@/classes/httpHelper';
  import MapComponent from '@/components/MapComponent';
  import AdminFeedNotificationsList from './AdminFeedNotificationsList';
  import AdminFeed from './AdminFeed';
  import translate from '../Mixins/Translate';
  import FeedStatsBox from './FeedStatsBox';
  import Events from '../../enums/event-names';
  import featureNames from '../../enums/feature-names';

  export default {
    name: 'admin-feed-page',
    mixins: [translate],
    components: {
      MapComponent,
      AdminFeed,
      AdminFeedNotificationsList,
      FeedStatsBox,
    },
    data() {
      return {
        regionItems: [],
        brandItems: [],
        items: [],
        featureNames,
      };
    },
    computed: {
      showNotificationsColumn() {
        return this.config.features[featureNames.likes]
          || this.config.features[featureNames.comments];
      },
      points() {
        return this.items.map((visit) => ({
          latitude: visit.customer[0].latitude,
          longitude: visit.customer[0].longitude,
          style: 'normal',
        }));
      },
      ...mapState(['config']),
    },
    async mounted() {
      // Debounce: This function is called max once within 3000 ms.
      // 'Leading' means that it is called immediately once,
      // and any following calls are debounced.
      const interval = parseInt(this.config.obj_texts.GetFeedStatsInterval, 10) * 1000;
      this.getFeedStats = debounce(this.getFeedStatsInternal, interval, {
        leading: true,
        trailing: false,
      });

      this.registerEventHandlers();

      await this.loadFeed();
      await this.getFeedStats();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.visitDeleted, this.onVisitDeleted);
        this.$bus.on(Events.visitCreated, this.onVisitCreated);
        this.$bus.on(Events.visitUpdated, this.onVisitUpdated);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.visitDeleted, this.onVisitDeleted);
        this.$bus.off(Events.visitCreated, this.onVisitCreated);
        this.$bus.off(Events.visitUpdated, this.onVisitUpdated);
      },
      async onVisitCreated(visit) {
        await this.getFeedStats();

        if (visit.type === 'rfi') return;
        if (visit.type === 'idea' && this.config.obj_texts.HideIdeasFromFeed === true) return;

        const { id } = visit;
        for (const item of this.items) {
          if (item.id === id) {
            console.warn('Duplicate item received. Skipping insertion.');
            return;
          }
        }
        this.items.unshift(visit);
      },
      async onVisitUpdated(updatedVisit) {
        await this.getFeedStats();
        const itemId = updatedVisit.id;
        for (const index in this.items) {
          if (index in this.items) {
            if (this.items[index].id === itemId) {
              // indexing into the array won't be detected by vue
              this.items.splice(index, 1, updatedVisit);
              break;
            }
          }
        }
      },
      async onVisitDeleted(itemId) {
        console.log('onVisitDeleted');
        await this.getFeedStats();
        const el = $(`#${itemId}`);
        el.fadeOut(() => {
          this.items = this.items.filter((item) => item.id !== itemId);
        });
      },
      async loadFeed() {
        const timestamp = moment().hour(0).minute(0).second(1)
          .toISOString();
        this.items = await httpGet(`visits?skiplikes&rand=${new Date().getTime()}&mintime=${timestamp}`);
      },

      // This should not be called directly. In the mounted hook it is wrapped in a debounced
      // version called getFeedStats, which should be called instead.
      async getFeedStatsInternal() {
        console.log('getFeedStats');

        const dayFromStr = moment().format('DD-MM-YYYY');
        const dayToStr = moment().format('DD-MM-YYYY');
        const data = await httpGet(`feedstats?periodfrom=${dayFromStr}&periodto=${dayToStr}`);

        this.regionItems = [];
        this.brandItems = [];
        for (const item of data) {
          if (item.vGroup === 'Region visits') {
            this.regionItems.push(item);
          }
          if (item.vGroup === 'Brand visits') {
            this.brandItems.push(item);
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .map-container {
    width: 100%;
    height: 400px;

    > div {
      width: 100%;
      height: 100%;
    }
  }
</style>
