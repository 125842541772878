<template>
  <div class='element materialShadow visits ThemeSection' :id='feedId'
       :class="[showNotificationsColumn ? 'size_50' : 'size_66']">
    <progressive-list-with-columns :items="items" :container-id="feedId" :columnWidth="200">
      <template v-slot="itemData">
        <feed-item :model="itemData.item"/>
      </template>
    </progressive-list-with-columns>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { guid } from '@/classes/guid';
  import ProgressiveListWithColumns from '../ProgressiveListWithColumns';
  import FeedItem from '../Pages/FeedItem';
  import featureNames from '../../enums/feature-names';

  export default {
    name: 'admin-feed',
    props: ['items'],
    components: {
      'progressive-list-with-columns': ProgressiveListWithColumns,
      'feed-item': FeedItem,
    },
    data() {
      return {
        feedId: guid(),
      };
    },
    computed: {
      showNotificationsColumn() {
        return this.config.features[featureNames.likes]
          || this.config.features[featureNames.comments];
      },
      ...mapState(['config']),
    },
  };
</script>
