<template>
  <div id="feed_region">
    <h2 class='headline'>{{ title }}</h2>
    <table>
      <thead>
      <tr>
        <td class='Theme_C1_BG'>{{ x('name') }}</td>
        <td class='Theme_C1_BG'>{{ x('count') }}</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ item.vType }}</td>
        <td>{{ item.vCount }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import translate from '../Mixins/Translate';

  export default {
    name: 'feed-stats-box',
    mixins: [translate],
    props: ['items', 'title'],
  };
</script>
